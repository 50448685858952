import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TopBarLayout } from '@layouts'
import { useNavigate, useParams } from 'react-router-dom'
import { useBook, useBookCategory } from '@hooks'
import {
    Button,
    Form,
    Spin,
    Select,
    Input,
    DatePicker,
    Popconfirm,
    Avatar,
    List,
    Skeleton,
} from 'antd'
import { ImgPicker, PickerFile } from '@common'
import { getBookImgUrl, threeDots } from '@/utils'
import { useAppSelector } from '@/redux/hook'
import { Box } from '@components'
import dayjs from 'dayjs'
import { BookRentHistory } from '@/apis/book'

const SpinWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const OneBook: React.FC = () => {
    const { id } = useParams()
    const nav = useNavigate()
    const { count, items, loading } = useAppSelector(
        (state) => state.bookCategory
    )
    const {
        bookRentHistory: { bookRentHistory, bookRent },
        getOneBook: { getOneBook, oneBook, getOneBookLoading },
        deleteBook: { deleteBook, deleteBookLoading },
        createBook: { createBook, createBookLoading },
        updateBook: { updateBook, updateBookLoading },
        updateBookStatus: { updateBookStatus, updateBookStatusLoading },
    } = useBook()
    const {
        getBookCategories: { getBookCategories },
    } = useBookCategory()
    const [bookImg, setBookImg] = useState<PickerFile>()

    const isEditing = id !== 'new'

    useEffect(() => {
        if (id && isEditing) {
            getOneBook(Number(id))
            bookRentHistory(Number(id))
        }

        if (!count) {
            getBookCategories()
        }
    }, [id])

    const onFinish = async (values: any) => {
        const year = dayjs(values.year).get('year')
        if (isEditing && id) {
            await updateBook({
                id: Number(id),
                body: {
                    ...values,
                    year,
                },
                bookImg,
            })
            return
        }

        await createBook({
            body: {
                ...values,
                year,
            },
            bookImg,
        })

        nav('/books', {
            replace: true,
        })
    }

    const onDelete = () => {
        oneBook && deleteBook(oneBook.id)
        nav('/books', {
            replace: true,
        })
    }

    const onChangeStatus = async () => {
        if (id) {
            await updateBookStatus(Number(id))
            await getOneBook(Number(id))
        }
    }

    return (
        <TopBarLayout
            withGoBack
            title={
                isEditing ? threeDots(oneBook?.name, 24) ?? '' : 'Новая книга'
            }
            postfix={
                isEditing && oneBook !== null ? (
                    <Box
                        style={{
                            width: '100%',
                            justifyContent: 'right',
                            gap: '30px',
                        }}
                    >
                        {oneBook.status === 'rented' ? (
                            <Popconfirm
                                title="Изъятие книги"
                                description="Вы хотите изъять книгу?"
                                okText="Да"
                                cancelText="Отмена"
                                onConfirm={onChangeStatus}
                            >
                                <Button loading={updateBookStatusLoading}>
                                    Изъять у {oneBook.renter?.full_name}
                                </Button>
                            </Popconfirm>
                        ) : (
                            <Button disabled>Книга свободна</Button>
                        )}
                        <Popconfirm
                            title="Удаление книги"
                            description={
                                oneBook?.status === 'rented'
                                    ? 'Книгу нельзя удалить если ее кто-то взял'
                                    : 'Вы хотите удалить книгу?'
                            }
                            okText="Удалить"
                            cancelText="Отмена"
                            onConfirm={onDelete}
                            okButtonProps={{
                                disabled:
                                    oneBook?.status === 'rented' ||
                                    getOneBookLoading,
                            }}
                        >
                            <Button danger loading={deleteBookLoading}>
                                Удалить
                            </Button>
                        </Popconfirm>
                    </Box>
                ) : null
            }
        >
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    paddingTop: '20px',
                }}
            >
                {(isEditing ? oneBook !== null : true) ? (
                    <Form
                        layout="vertical"
                        initialValues={{
                            name: oneBook?.name,
                            author: oneBook?.author,
                            description: oneBook?.description,
                            year: oneBook?.year
                                ? dayjs().set('year', oneBook.year)
                                : null,
                            category_id: oneBook?.category.id,
                        }}
                        onFinish={onFinish}
                        style={{
                            width: '50%',
                        }}
                    >
                        <Form.Item>
                            <Box
                                style={{
                                    justifyContent: 'center',
                                }}
                            >
                                <ImgPicker
                                    src={getBookImgUrl(oneBook?.photo ?? null)}
                                    onFilePicked={(file) => setBookImg(file)}
                                    fileTypes={['.png', '.jpg', '.jpeg']}
                                    size="100px"
                                    forBook
                                />
                            </Box>
                        </Form.Item>
                        <Form.Item
                            name="name"
                            label="Название"
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, введите название',
                                },
                            ]}
                        >
                            <Input placeholder="Кэрри" />
                        </Form.Item>
                        <Form.Item
                            name="author"
                            label="Автор"
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, введите автора',
                                },
                            ]}
                        >
                            <Input placeholder="Ствиен Кинг" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Описание"
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, введите описание',
                                },
                            ]}
                        >
                            <Input.TextArea placeholder="Книга про девушку по имени Кэрри" />
                        </Form.Item>
                        <Form.Item
                            name="year"
                            label="Год"
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, выберите год',
                                },
                            ]}
                        >
                            <DatePicker.YearPicker
                                style={{ width: '100%' }}
                                allowClear={false}
                                disabledDate={(date) => date > dayjs()}
                                placeholder="2023"
                            />
                        </Form.Item>
                        <Form.Item
                            name="category_id"
                            label="Категория"
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста, выберите категорию',
                                },
                            ]}
                        >
                            <Select
                                loading={loading}
                                fieldNames={{
                                    value: 'id',
                                    label: 'name',
                                }}
                                options={items}
                                placeholder="триллеры"
                            ></Select>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ width: '100%' }}
                                loading={
                                    getOneBookLoading ||
                                    (isEditing
                                        ? updateBookLoading
                                        : createBookLoading)
                                }
                            >
                                {isEditing ? 'Изменить' : 'Создать'}
                            </Button>
                        </Form.Item>
                    </Form>
                ) : (
                    <SpinWrapper>
                        <Spin />
                    </SpinWrapper>
                )}

                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: '40px',
                        width: '50%',
                    }}
                >
                    <h3 style={{ marginTop: 0 }}>История аренды книг</h3>
                    <BookRentHistoryComponent rentHistory={bookRent} />
                </Box>
            </Box>
        </TopBarLayout>
    )
}

function BookRentHistoryComponent(prop: { rentHistory: BookRentHistory[] }) {
    const showCount = 5
    const [listData, setListData] = useState<BookRentHistory[]>([])

    function renderDate(createdAt: Date | null) {
        if (!createdAt) return 'не обнаружено'

        const createdDate = new Date(createdAt).toLocaleString('ru-RU', {
            timeZone: 'asia/tashkent',
            hour12: false,
        })

        return createdDate.substring(0, 17)
    }

    useEffect(() => {
        setListData(prop.rentHistory.slice(0, showCount))
    }, [prop.rentHistory])

    const onLoadMore = () => {
        setListData((prev) =>
            prop.rentHistory.slice(0, prev.length + showCount)
        )
        // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
        // In real scene, you can using public method of react-virtualized:
        window.dispatchEvent(new Event('resize'))
    }

    const loadMore = !(prop.rentHistory.length === listData.length) ? (
        <div
            style={{
                textAlign: 'center',
                marginTop: 12,
                height: 32,
                lineHeight: '32px',
            }}
        >
            <Button onClick={onLoadMore}>Загрузить больше</Button>
        </div>
    ) : null

    return (
        <List
            style={{ width: '100%' }}
            itemLayout="horizontal"
            loadMore={loadMore}
            dataSource={listData}
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        title={<span>{item.renter.full_name}</span>}
                        description={
                            <>
                                <div>
                                    <b>Rent: </b>
                                    {renderDate(item.rent_date)}
                                </div>
                                <div>
                                    <b>Return: </b>
                                    {renderDate(item.return_date)}
                                </div>
                            </>
                        }
                    />
                </List.Item>
            )}
        />
    )
}

export default OneBook
