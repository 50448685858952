import {
    BookRentHistory,
    CreateBookProps,
    CreateBookResponse,
    DeleteBookResponse,
    GetBooksResponse,
    GetOneBookResponse,
    UpdateBookImgProps,
    UpdateBookProps,
    UpdateBookResponse,
} from './types'
import httpService from '@apis/httpService'

export default {
    getBooks() {
        return httpService.get<GetBooksResponse>('/dashboard/books')
    },
    createBook({ body }: CreateBookProps) {
        return httpService.post<CreateBookResponse>('/dashboard/books', body)
    },
    getOneBook(id: number) {
        return httpService.get<GetOneBookResponse>(`/dashboard/books/${id}`)
    },
    getBookRentHistory(id: number) {
        return httpService.get<BookRentHistory[]>(
            `/dashboard/books/rent-history/${id}`
        )
    },
    updateBook({ id, body }: UpdateBookProps) {
        return httpService.put<UpdateBookResponse>(
            `/dashboard/books/${id}`,
            body
        )
    },
    updateBookImg({ id, body }: UpdateBookImgProps) {
        return httpService.patch<null>(`/dashboard/books/${id}`, body)
    },
    updateBookStatus(id: number) {
        return httpService.patch(
            `/dashboard/books/status/${id}?status=available`
        )
    },
    deleteBook(id: number) {
        return httpService.delete<DeleteBookResponse>(`/dashboard/books/${id}`)
    },
}
