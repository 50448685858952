import React, { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hook'
import { Button, Form, Input, Modal, Table } from 'antd'
import type { TableProps } from 'antd'
import { useStaff } from '@hooks'
import { GetStaffResponseItem } from '@apis/staff'
import { CreateUserModal } from '@common'
import { Link, useNavigate } from 'react-router-dom'
import { getColumnData, getAvatarUrl } from '@/utils'
import { TopBarLayout } from '@layouts'
import { AiOutlineMail } from 'react-icons/ai'
import { Box } from '@components'
import AutoSizer from 'react-virtualized-auto-sizer'

const Staff: React.FC = () => {
    const {
        items,
        loading,
        count,
        filters: { page, limit, search },
    } = useAppSelector((state) => state.staff)

    const nav = useNavigate()

    const {
        getStaff: { getStaff },
        updateStaff: { updateStaff },
        resendInvite: { resendInvite, resendInviteLoading },
    } = useStaff()

    const [visibility, setVisibility] = useState(false)
    const [resendInviteOpen, setResendInviteOpen] = useState(false)
    const [searchValue, setSearchValue] = useState(search || '')
    const [staff, setStaff] = useState<GetStaffResponseItem>()

    const { items: staffGroups, loading: staffGroupsLoading } = useAppSelector(
        (state) => state.staffGroup
    )
    const { loading: teamsLoading, items: teams } = useAppSelector(
        (state) => state.team
    )

    const [groupFilters, setGroupFilters] = useState<any[]>([])
    const [teamFilters, setTeamFilters] = useState<any[]>([])

    const onSearch = async () => {
        const search = searchValue.trim()
        if (search) {
            await getStaff({ page: 1, search })
        } else {
            await getStaff({ page: 1, search: undefined })
        }
    }

    const onResendInvite = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        staffData: GetStaffResponseItem
    ) => {
        e.stopPropagation()
        setResendInviteOpen(true)
        setStaff(staffData)
    }

    useEffect(() => {
        if (!count) getStaff()
        // eslint-disable-next-line
    }, [count])

    useEffect(() => {
        if (staffGroups && staffGroups.length > 0) {
            const filters = staffGroups.map((group) => ({
                text: group.name,
                value: group.name,
            }))
            setGroupFilters(filters)
        }

        if (teams && teams.length > 0) {
            const filters = teams.map((team: any) => ({
                text: team.name,
                value: team.name,
            }))
            setTeamFilters(filters)
        }
    }, [teams, staffGroups])

    const columns: TableProps<GetStaffResponseItem>['columns'] = [
        {
            title: '№',
            dataIndex: 'index',
            render: (_: any, __: any, index: number) => index + 1,
            width: '5%',
            align: 'center',
            responsive: ['xs', 'sm'],
        },
        {
            title: 'Фото',
            dataIndex: 'photo_filename',
            render: (_, { photo_filename }) => (
                <img
                    src={getAvatarUrl(photo_filename)}
                    className="staff-avatar"
                    width={window.innerWidth < 768 ? 50 : 100}
                    height={window.innerWidth < 768 ? 50 : 100}
                    alt="avatar"
                />
            ),
            width: 130,
            align: 'center',
        },
        {
            title: 'Полное имя',
            dataIndex: 'full_name',
            render: getColumnData,
            align: 'center',
        },
        {
            title: 'Номер телефона',
            dataIndex: 'phone_number',
            render: getColumnData,
            align: 'center',
        },
        {
            title: 'Группа',
            dataIndex: 'staff_group',
            filters: groupFilters,
            onFilter: (value, record) =>
                record.staff_group?.name.indexOf(value as string) === 0,
            sorter: (a, b) =>
                a.staff_group?.name.localeCompare(b.staff_group?.name),
            render: (staff_group) => staff_group?.name,
            align: 'center',
        },
        {
            title: 'Проект',
            dataIndex: 'staff_team',
            filters: teamFilters,
            onFilter: (value, record) =>
                record.staff_team?.name.indexOf(value as string) === 0,
            sorter: (a, b) =>
                a.staff_team?.name.localeCompare(b.staff_team?.name),
            render: (staff_team) => staff_team?.name,
            align: 'center',
        },
        {
            title: 'Статус',
            dataIndex: 'staff_status',
            render: (staff_status) => getColumnData(staff_status?.name),
            align: 'center',
            responsive: ['md'], // Скрыть на малых экранах
        },
        {
            title: 'Доступы',
            dataIndex: 'staff_permissions',
            render: (_, { staff_permissions }) =>
                staff_permissions
                    .map((staff_permission) => staff_permission.name)
                    .join(', '),
            align: 'center',
            responsive: ['md'], // Скрыть на малых экранах
        },
    ]

    const getColumns = () => {
        const result = [...columns]

        result.push({
            title: <AiOutlineMail style={{ width: '100%' }} size={20} />,
            render: (_, staffData) =>
                staffData.staff_status.name === 'Pending' ? (
                    <Button onClick={(e) => onResendInvite(e, staffData)}>
                        Отправить приглашение
                    </Button>
                ) : null,
            width: 228,
        })

        return result
    }

    const onFinish = async ({ phone_number }: any) => {
        if (staff === undefined) return
        const phoneNumber = `+${phone_number}`

        if (staff.phone_number !== phoneNumber) {
            await updateStaff({ phone_number: phoneNumber, id: staff.id })
        }

        await resendInvite(staff.id)
    }

    return (
        <TopBarLayout
            title="Сотрудники"
            withSearchBox
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onSearch={onSearch}
            postfix={
                <Box style={{ gap: '20px' }}>
                    <Link to="staff/archive">
                        <Button>Архив</Button>
                    </Link>
                    {
                        <Button onClick={() => setVisibility(true)}>
                            Новый сотрудник
                        </Button>
                    }
                </Box>
            }
        >
            <AutoSizer style={{ width: '100%' }}>
                {({ width, height }: any) => {
                    return (
                        <Table<GetStaffResponseItem>
                            style={{ width: '100%' }}
                            dataSource={items}
                            columns={getColumns()}
                            rowKey="id"
                            loading={
                                loading || teamsLoading || staffGroupsLoading
                            }
                            pagination={{
                                total: count,
                                pageSize: limit,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '50', '100'],
                                onChange: (page, pageSize) =>
                                    getStaff({ page, limit: pageSize }),
                                current: page,
                            }}
                            onRow={(data, index) => {
                                return {
                                    onClick: (event) =>
                                        nav(`/staff/${data.id}`),
                                }
                            }}
                            scroll={{ x: 1400 }}
                        />
                    )
                }}
            </AutoSizer>
            <CreateUserModal
                changeVisibility={(visibility) => setVisibility(visibility)}
                visibility={visibility}
            />
            <Modal
                open={resendInviteOpen}
                onCancel={() => setResendInviteOpen(false)}
                destroyOnClose
                footer={null}
                width="350px"
            >
                <Form
                    layout="vertical"
                    initialValues={{
                        phone_number: staff?.phone_number.split('+')[1],
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Номер телефона"
                        name="phone_number"
                        style={{ width: '300px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Please input your phone number!',
                                pattern: /^\d{12}$/,
                            },
                        ]}
                    >
                        <Input
                            style={{ width: '300px' }}
                            placeholder="998991234567"
                            prefix="+"
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={resendInviteLoading}
                        >
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </TopBarLayout>
    )
}

export default Staff
