import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hook'
import { useRestriction } from './useRestriction'
import { SET_MESSAGE } from '@/redux/message'
import Apis from '@apis'
import {
    CreateBookProps,
    GetOneBookResponse,
    UpdateBookProps,
    BookRentHistory,
} from '@apis/book'
import { ADD_BOOK, getBooks, REMOVE_BOOK, UPDATE_BOOK } from '@/redux/book'
import { useNavigate } from 'react-router-dom'
import { UPDATE_STAFF } from '@/redux/staff'

export const useBook = () => {
    const { loading } = useAppSelector((state) => state.book)
    const nav = useNavigate()
    const dispatch = useAppDispatch()
    const { isAuth } = useRestriction()

    const [updateBookLoading, setUpdateBookLoading] = useState(false)
    const [deleteBookLoading, setDeleteBookLoading] = useState(false)
    const [createBookLoading, setCreateBookLoading] = useState(false)
    const [getOneBookLoading, setGetOneBookLoading] = useState(false)
    const [updateBookStatusLoading, setUpdateBookStatusLoading] =
        useState(false)

    const [oneBook, setOneBook] = useState<GetOneBookResponse | null>(null)
    const [bookRent, setBookRent] = useState<BookRentHistory[]>([])

    const fetchBooks = async () => {
        if (!isAuth) return

        try {
            await dispatch(getBooks())
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить категории книг. Ошибка ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const getOneBook = async (bookId: number) => {
        if (!isAuth) return

        setGetOneBookLoading(true)
        try {
            const { data } = await Apis.book.getOneBook(bookId)
            setOneBook(data)
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить книгу. Ошибка ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setGetOneBookLoading(false)
    }

    const bookRentHistory = async (bookId: number) => {
        if (!isAuth) return

        try {
            const { data } = await Apis.book.getBookRentHistory(bookId)
            setBookRent(data)
        } catch (e: any) {
            dispatch(
                SET_MESSAGE({
                    text: `Не удалось загрузить книгу. Ошибка ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
    }

    const updateBook = async (
        updatedBookData: UpdateBookProps & { bookImg?: File }
    ) => {
        if (!isAuth) return

        setUpdateBookLoading(true)
        try {
            const { data } = await Apis.book.updateBook(updatedBookData)
            setOneBook((prev) =>
                prev == null
                    ? null
                    : {
                          ...prev,
                          name: data.name,
                      }
            )
            await dispatch(
                SET_MESSAGE({
                    text: 'Книга успешно обновлена',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось обновить категорию. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        if (updatedBookData.bookImg) {
            try {
                const formData = new FormData()
                formData.append('file', updatedBookData.bookImg)
                const { data } = await Apis.book.updateBookImg({
                    body: formData,
                    id: updatedBookData.id,
                })
            } catch (e: any) {
                dispatch(
                    SET_MESSAGE({
                        text: `Не удалось загурзить изображение. Ошибка: ${
                            e?.response?.data?.message ?? e.message
                        }`,
                        type: 'error',
                    })
                )
            }
        }
        fetchBooks()
        setUpdateBookLoading(false)
    }

    const createBook = async (
        newBookData: CreateBookProps & { bookImg?: File }
    ) => {
        if (!isAuth) return
        let id = 0

        setCreateBookLoading(true)
        try {
            const { data } = await Apis.book.createBook(newBookData)
            id = data.id
            await dispatch(
                SET_MESSAGE({
                    text: 'Книга успешно создана',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось создать книгу. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }

        if (newBookData.bookImg) {
            try {
                const formData = new FormData()
                formData.append('file', newBookData.bookImg)
                const { data } = await Apis.book.updateBookImg({
                    body: formData,
                    id,
                })
            } catch (e: any) {
                dispatch(
                    SET_MESSAGE({
                        text: `Не удалось загурзить изображение. Ошибка: ${
                            e?.response?.data?.message ?? e.message
                        }`,
                        type: 'error',
                    })
                )
            }
        }
        await fetchBooks()
        nav(`/books/${id}`, {
            replace: true,
        })
        setCreateBookLoading(false)
    }

    const deleteBook = async (deletedBookId: number) => {
        if (!isAuth) return

        setDeleteBookLoading(true)
        try {
            const { data } = await Apis.book.deleteBook(deletedBookId)
            dispatch(
                REMOVE_BOOK({
                    categoryId: data.category_id,
                    bookId: deletedBookId,
                })
            )
            await dispatch(
                SET_MESSAGE({
                    text: 'Книга успешно удалена',
                    type: 'success',
                })
            )
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось удалить категорию. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setDeleteBookLoading(false)
    }

    const updateBookStatus = async (id: number) => {
        if (!isAuth) return

        setUpdateBookStatusLoading(true)
        try {
            await Apis.book.updateBookStatus(id)
        } catch (e: any) {
            await dispatch(
                SET_MESSAGE({
                    text: `Не удалось поменть статус книги. Ошибка: ${
                        e?.response?.data?.message ?? e.message
                    }`,
                    type: 'error',
                })
            )
        }
        setUpdateBookStatusLoading(false)
    }

    return {
        getBooks: {
            getBooks: fetchBooks,
            getBooksLoading: loading,
        },
        getOneBook: {
            getOneBook,
            getOneBookLoading,
            oneBook,
        },
        bookRentHistory: {
            bookRentHistory,
            bookRent,
        },
        updateBook: {
            updateBook,
            updateBookLoading,
        },
        updateBookStatus: {
            updateBookStatus,
            updateBookStatusLoading,
        },
        createBook: {
            createBook,
            createBookLoading,
        },
        deleteBook: {
            deleteBook,
            deleteBookLoading,
        },
    }
}
